import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Page404 from "containers/Page404/Page404";
import CreateInfluencerAccountPage from "containers/AccountPage/CreateInfluencerAccountPage";
import InfluencerLogin from "containers/PageLogin/InfluencerLogin";
import SiteHeader from "containers/SiteHeader";
import InfluencerDetailPage from "containers/InfluencerDetails/InfluencerDetailPage";
import SignUp from "containers/Onboard/SignUp";
import Login from "containers/Onboard/Login";
import ChangePasswordInfluencer from "containers/ChangePasswordInfluencer";
import ForgotPasswordInfluencer from "containers/ForgotPasswordInfluencer";
import VerifyInfluencerOTP from "containers/VerifyInfluencerOTP";
import InfluencerVerified from "containers/InfluencerVerified";
import VerifyInfluencer from "containers/VerifyInfluencer";
import InfluencerLoggedOut from "containers/PageLoggedOut/InfluencerLogin";
import ResetSuccessfulInfluencer from "containers/ResetSuccessfulInfluencer";
import Invoices from "containers/NewDashboard/Invoices";
import Dashboard from "containers/NewDashboard/Dashboard";
import Support from "containers/NewDashboard/Support";
import Messages from "containers/NewDashboard/Messages";
import Payments from "containers/NewDashboard/Payments";
import Settings from "containers/NewDashboard/Settings";
import Proposals from "containers/NewDashboard/Proposals";
import RateCard from "containers/NewDashboard/RateCard";
import Contracts from "containers/NewDashboard/Contracts";
import Deliverables from "containers/NewDashboard/Deliverables";
import EditDeliverables from "containers/NewDashboardEdit/EditDeliverables";
import CreateInvoice from "containers/NewDashboardEdit/CreateInvoice";
import EditPayments from "containers/NewDashboardEdit/EditPayments";
import GeneralInfo from "containers/NewDashboardEdit/GeneralInfo";
import PasswordUpdate from "containers/NewDashboardEdit/PasswordUpdate";
import ViewDeliverable from "containers/NewDashboardEdit/ViewDeliverable";
import PreviewingInvoice from "containers/NewDashboardEdit/PreviewingInvoice";
import CreateDeliverableInvoice from "containers/NewDashboardEdit/CreateDeliverableInvoice";
import Videos from "containers/NewDashboardEdit/Videos";
import Socials from "containers/NewDashboardEdit/Socials";
import DeliverContent from "containers/NewDashboardEdit/DeliverContent";
import ChatInvoice from "containers/NewDashboardEdit/ChatInvoice";
import TaskManager from "containers/NewDashboard/TaskManager";
import InfluencerAccount from "containers/VerifyAccount/InfluencerAccount";
import Success from "containers/StripePages/Success";
import Failed from "containers/StripePages/Failed";
import Banner from "containers/Banner/Banner";
import OnboardUser from "containers/AccountPage/OnboardUser";
import CreateInfluencerPage from "containers/SubscriptionFlow/CreateInfluencerPage";
import Verify from "containers/SubscriptionFlow/Verify";
import Verified from "containers/SubscriptionFlow/Verified";
import MessageConvo from "containers/NewDashboardEdit/MessageConvo";
import Welcome from "containers/NewDashboard/Welcome";
import PaymentNotification from "containers/FlutterwavePage/PaymentNotification";
import { CrispProvider } from "components/CrispChat/CrispProvider";
import SubscriptionRoutes from "containers/AB-ROUTING/SubscriptionRoutes";
import RateCardVideo from "containers/BlogPage/RateCardVideo";

export const pages: Page[] = [
  { path: "/", exact: true, component: InfluencerLogin },
  { path: "/#", exact: true, component: InfluencerLogin },
  { path: "/signup", component: CreateInfluencerAccountPage },
  { path: "/login", component: InfluencerLogin },
  { path: "/welcome/:userName", component: OnboardUser },


  //SUBSCRIPTION FLOW
  { path: "/create/:priceId/:amount", component: CreateInfluencerPage },
  { path: "/verify/:priceId/:amount", component: Verify },
  { path: "/verified/:priceId/:amount", component: Verified },



  { path: "/verifyCreator", component: VerifyInfluencer },
  { path: "/verified", component: InfluencerVerified },

  //NOT VERIFIED
  { path: "/email/creator/verify", component: InfluencerAccount },


  { path: "/logged-out", component: InfluencerLoggedOut },


  //Password Reset
  { path: "/forgot-password", component: ForgotPasswordInfluencer },
  { path: "/verifyCreatorOTP/:email", component: VerifyInfluencerOTP },
  { path: "/reset-password", component: ChangePasswordInfluencer },
  { path: "/successful", component: ResetSuccessfulInfluencer },

  //MAIN DASHBOARD - CREATOR
  { path: "/home", component: Welcome },
  { path: "/dashboard", component: Dashboard },
  { path: "/card", component: RateCard },
  //{ path: "/tasks", component: TaskManager },
  { path: "/deliverables", component: Deliverables },
  { path: "/proposals", component: Proposals },
  { path: "/invoices", component: Invoices },
  { path: "/contracts", component: Contracts },
  { path: "/inbox", component: Messages },
  { path: "/chat/:chatId", component: MessageConvo },
  { path: "/wallet", component: Payments },
  { path: "/settings", component: Settings },
  { path: "/support", component: Support },

  //EDIT DASHBOARD - CREATOR
  { path: "/edit-deliverables", component: EditDeliverables },
  { path: "/invoice", component: CreateInvoice },
  { path: "/preview/:invoiceId", component: PreviewingInvoice },
  { path: "/ngn/add", component: EditPayments },
  { path: "/profile", component: GeneralInfo },
  { path: "/password-update", component: PasswordUpdate },
  { path: "/view/:username/:deliverableId", component: ViewDeliverable },
  {
    path: "/generate/invoice/:deliverableId",
    component: CreateDeliverableInvoice,
  },
  { path: "/new/invoice/:businessName", component: ChatInvoice },


  { path: "/socials", component: Socials },
  { path: "/videos", component: Videos },

  { path: "/deliver/:username/:indexId", component: DeliverContent },

  //STRIPE PAGES
  { path: "/success", component: Success },
  { path: "/failed", component: Failed },

  //FLUTTERWAVE
  { path: "/payment-notification", component: PaymentNotification },

  //PROMOTIONAL LINKS
  { path: "/influencer-rate-card", component: RateCardVideo },
];

const Routes = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <CrispProvider />
        <ScrollToTop />
        <Banner />
        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}
          <Route exact path="/:username" component={InfluencerDetailPage} />

          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;