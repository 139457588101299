// @ts-nocheck
import React, { FC, useState, useEffect, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";
import { InfluencerData } from "routers/types";
import newRequest from "utils/newRequest";
import Logo from "shared/Logo/Logo";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface VerifyInfluencerOTPProps {
  className?: string;
}

const VerifyInfluencerOTP: FC<VerifyInfluencerOTPProps> = ({
  className = "",
}) => {
  const location = useLocation();
  const email = location.pathname.split("/").pop();
  const [influencer, setInfluencer] = useState<InfluencerData | {}>({});
  const [otpInput, setOtpInput] = useState<Array<string>>(["", "", "", ""]);
  const [error, setError] = useState({});

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const history = useHistory();

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const nextInput = inputRefs.current[+name + 1];

    // Handle paste event
    if (e.type === "paste") {
      e.preventDefault();
      const pastedText = e.clipboardData.getData("text");
      const pastedDigits = pastedText.split("");
      setOtpInput((prevState) => {
        const newState = [...prevState];
        for (let i = 0; i < 4 && i < pastedDigits.length; i++) {
          newState[i] = pastedDigits[i];
        }
        return newState;
      });
      return;
    }

    // Handle regular input event
    setOtpInput((prevState) =>
      prevState.map((digit, index) => (index === +name ? value : digit))
    );

    if (value && nextInput) {
      nextInput.focus();
    }
  };

  //
  useEffect(() => {
    async function fetchData() {
      const response = await newRequest.get(`/influencer/getByEmail/${email}`);
      setInfluencer(response.data);
    }
    fetchData();
  }, [email]);
  //

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const newOTP = {
        OTP: parseInt(otpInput.join(""), 10),
      };
      const verify = await newRequest.post(
        `/auth-influencer/verify-password-reset/${influencer._id}`,
        newOTP
      );
      console.log(verify.status);
      history.push(`/reset-password/${influencer?.email}`);
    } catch (error) {
      console.log(error);
      setError({
        message:
          "This OTP Code has expired. Please, click on the 'Resend OTP' Button",
      });
    }
  };

  return (
    <div
      className={`nc-VerifyInfluencerOTP h-screen ${className}`}
      data-nc-id="VerifyInfluencerOTP"
    >
      <Helmet>
        <title>Verify account</title>
      </Helmet>
      <nav className="bg-white border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
          <Logo />

          <div className="hidden w-full md:block md:w-auto">
            <SwitchDarkMode />
          </div>
        </div>
      </nav>

      <section className="items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-20 sm:py-20 md:py-16">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-lg lg:max-w-lg md:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 sm:p-8">
              <h1 className="mb-4 text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Verify your email address
              </h1>
              <p class="font-light mb-10 text-sm text-gray-500 dark:text-gray-400">
                We emailed you a four-digit code to{" "}
                <span className="underline text-primary-6000">
                  {influencer?.email}
                </span>
                . Enter the code below to confirm your email address.
              </p>

              <div className="max-w-md mx-auto">
                <div
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleVerification(e);
                    }
                  }}
                >
                  <form>
                    <div className="flex flex-col space-y-16">
                      <div className="flex flex-row justify-between w-full max-w-xs mx-auto items-left">
                        {otpInput.map((digit, index) => (
                          <div className="w-16 h-16" key={index}>
                            <input
                              ref={(el) => (inputRefs.current[index] = el)}
                              maxLength={1}
                              className="flex flex-col w-full h-full px-5 text-2xl text-center bg-white border items-left justify-left border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-xl"
                              type="text"
                              name={String(index)}
                              value={digit}
                              onChange={handleOtpChange}
                              onPaste={handleOtpChange}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="flex flex-col space-y-5">
                        <div>
                          <ButtonPrimary
                            type="button"
                            className="w-full py-4"
                            onClick={handleVerification}
                          >
                            Verify account
                          </ButtonPrimary>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VerifyInfluencerOTP;