import React, { FC, useContext } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { InfluencerAuthContext } from "context/InfluencerAuthContext";
import { Tab } from "@headlessui/react";

export interface PageEditProfileProps {
  className?: string;
}

const PageEditProfile: FC<PageEditProfileProps> = ({ className = "" }) => {
  const { influencer } = useContext(InfluencerAuthContext);

  return (
    <div
      className={`nc-PageEditProfile pr-1 pl-1 xl:pl-4 xl:pr-4 xl:pb-[200px] lg:pb-[200px] md:pb-[200px] sm:pb-[200px] pb-[285px] ${className}`}
      data-nc-id="PageEditProfile"
    >
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      <div className="py-16 mt-4 mb-24 space-y-16 lg:pb-28 lg:pt-20 lg:space-y-28">
        <main>
          {/* HEADING */}
          <Tab.Group>
            <div className="flex flex-col justify-between lg:flex-row ">
              <Tab.List className="flex space-x-0 overflow-x-auto sm:space-x-2">
                <Tab>
                  {() => (
                    <div className="flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900">
                      Settings
                    </div>
                  )}
                </Tab>
              </Tab.List>
            </div>
          </Tab.Group>
          <div className="max-w-2xl">
            <span className="block mt-8 mb-2 text-sm text-neutral-500 dark:text-neutral-400">
              Choose from the following to update any part of your account.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

          <div className="mt-10 space-y-5 sm:space-y-6 md:sm:space-y-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-5 lg:grid-cols-3 md:grid-cols-2 xl:grid-cols-4">
              <div className="max-w-sm">
                <div className="max-w-sm p-6 border border-gray-200 shadow-lg bg-gray-50 rounded-xl dark:bg-gray-800 dark:border-gray-700">
                  <svg
                    className="w-10 h-10 mb-2 text-gray-500 dark:text-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.2101 15.74L15.67 19.2801C15.53 19.4201 15.4 19.68 15.37 19.87L15.18 21.22C15.11 21.71 15.45 22.05 15.94 21.98L17.29 21.79C17.48 21.76 17.75 21.63 17.88 21.49L21.42 17.95C22.03 17.34 22.32 16.63 21.42 15.73C20.53 14.84 19.8201 15.13 19.2101 15.74Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.7001 16.25C19.0001 17.33 19.84 18.17 20.92 18.47"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.40991 22C3.40991 18.13 7.25994 15 11.9999 15C13.0399 15 14.0399 15.15 14.9699 15.43"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <h5 className="mb-2 text-2xl font-normal tracking-tight text-gray-900 dark:text-white">
                    Profile
                  </h5>

                  <p className="mb-8 font-normal text-gray-500 dark:text-gray-400">
                    Update your email, profile image, username, or bio.
                  </p>
                  <Link to={"/profile"}>
                    <ButtonPrimary sizeClass="px-5 py-2" type="button">
                      <span>Update</span>
                      <svg
                        className="w-4 h-4 ml-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                    </ButtonPrimary>
                  </Link>
                </div>
              </div>

              <div className="max-w-sm">
                <div className="max-w-sm p-6 border border-gray-200 shadow-lg bg-gray-50 rounded-xl dark:bg-gray-800 dark:border-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-10 h-10 mb-2 text-gray-500 dark:text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                    />
                  </svg>

                  <h5 className="mb-2 text-2xl font-normal tracking-tight text-gray-900 dark:text-white">
                    Subscription
                  </h5>

                  <p className="mb-8 font-normal text-gray-500 dark:text-gray-400">
                    Update details of your subscription plan.
                  </p>
                  <Link to={"/subscription"}>
                    <ButtonPrimary sizeClass="px-5 py-2" type="button">
                      <span>Update</span>
                      <svg
                        className="w-4 h-4 ml-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                    </ButtonPrimary>
                  </Link>
                </div>
              </div>

              <div className="max-w-sm">
                <div className="max-w-sm p-6 border border-gray-200 shadow-lg bg-gray-50 rounded-xl dark:bg-gray-800 dark:border-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-10 h-10 mb-2 text-gray-500 dark:text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                    />
                  </svg>

                  <h5 className="mb-2 text-2xl font-normal tracking-tight text-gray-900 dark:text-white">
                    Password
                  </h5>

                  <p className="mb-8 font-normal text-gray-500 dark:text-gray-400">
                    Update your password and secure your account.
                  </p>
                  <Link to={"/password-update"}>
                    <ButtonPrimary sizeClass="px-5 py-2" type="button">
                      <span>Update</span>
                      <svg
                        className="w-4 h-4 ml-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                    </ButtonPrimary>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageEditProfile;