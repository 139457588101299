// @ts-nocheck
import React, { FC, useContext } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { InfluencerAuthContext } from "context/InfluencerAuthContext";
import Logo from "shared/Logo/Logo";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface ResetSuccessfulInfluencerProps {
  className?: string;
}

const ResetSuccessfulInfluencer: FC<ResetSuccessfulInfluencerProps> = ({
  className = "",
}) => {
  const { influencer } = useContext(InfluencerAuthContext);

  return (
    <div
      className={`nc-ResetSuccessfulInfluencer h-screen ${className}`}
      data-nc-id="ResetSuccessfulInfluencer"
    >
      <Helmet>
        <title>Reset Successful</title>
      </Helmet>
      <nav className="bg-white border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-wrap items-center justify-between max-w-screen-lg p-4 mx-auto">
          <Logo />

          <div className="hidden w-full md:block md:w-auto">
            <SwitchDarkMode />
          </div>
        </div>
      </nav>

      <section className="items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-20 sm:py-20 md:py-16">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md lg:max-w-lg md:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 sm:p-8">
              <svg
                class="fill-green-500 dark:fill-green-300 w-16 h-16"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>

              <h1 className="mt-2 mb-4 text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Reset successful
              </h1>
              <p class="font-light text-sm text-gray-500 dark:text-gray-400">
                You can log in to your account now.
              </p>

              <div className="flex flex-col w-full max-w-md mx-auto mt-10 space-y-16">
                {influencer ? (
                  <ButtonPrimary href="/dashboard">
                    Go to your Dashboard
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary href="/login">Login</ButtonPrimary>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetSuccessfulInfluencer;