// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Tab } from "@headlessui/react";
import FormItem from "components/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";
import { FaRobot } from "react-icons/fa";
import Badge from "shared/Badge/Badge";

const OmniMessenger: React.FC = () => {
  const [showDemoModal, setShowDemoModal] = useState(false);

  const [showBotModal, setShowBotModal] = useState(false);

  const renderDemoContent = () => {
    return (
      <div className="ml-1 mr-1">
        <iframe
          className="w-full h-80 rounded-2xl"
          src="https://www.youtube.com/embed/fiUI0koisHY"
          title="Simplified Collaboration Workflows for Creators: GetCollabo"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>

        <div className="mt-4 space-x-3">
          <ButtonSecondary
            sizeClass="px-5 py-2"
            onClick={() => {
              setShowDemoModal(false);
            }}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              />
            </svg>

            <span>Close</span>
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderBotContent = () => {
    return (
      <div className="ml-1 mr-1">
        <div className="flex items-center justify-center">
          <FaRobot size={60} className="mb-2 fill-primary-6000" />
        </div>
        <span className="text-base">
          Your personal AI lawyer at your fingertips. Get expert legal help on
          collaborations anytime, anywhere.
        </span>

        <div className="mt-4">
          <Badge
            className="px-6 py-2 text-center rounded"
            color="green"
            name="Coming soon"
          />
        </div>

        <div className="mt-4 space-x-3">
          <ButtonSecondary
            sizeClass="px-5 py-2"
            onClick={() => {
              setShowBotModal(false);
            }}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              />
            </svg>

            <span>Close</span>
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ViewContract h-screen pr-1 pl-1 xl:pl-4 xl:pr-4`}
      data-nc-id="ViewContract"
    >
      <Helmet>
        <title>Booking Contracts</title>
      </Helmet>
      <div className="mt-20 xl:mt-24">
        <main>
          <Tab.Group>
            <div className="flex flex-col justify-between lg:flex-row ">
              <Tab.List className="flex space-x-0 overflow-x-auto sm:space-x-2">
                <Tab>
                  {() => (
                    <div className="flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900">
                      Inbox
                    </div>
                  )}
                </Tab>
              </Tab.List>
            </div>
          </Tab.Group>

          {/** 
          <p
            onClick={() => setShowDemoModal(true)}
            className="inline-flex mt-4 text-primary-6000"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
              />
            </svg>

            <span className="text-sm">Take a tour of Booking Contracts</span>
          </p>
          */}

          <p className="mt-4 mb-2 text-sm font-normal text-gray-500 dark:text-gray-400">
            Easily integrate all your messaging channels in one place. WhatsApp, Gmail, Instagram, Twitter, TikTok, and Facebook. Read all your messages in a click.
          </p>

          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

          <div class="bg-white dark:bg-gray-900 mt-48 mb-96 flex flex-col items-center justify-center text-center">
            <div class="">
              <h1 class="text-4xl font-bold">Coming Soon</h1>
              <p class="mt-4 text-lg">We're working on something awesome!</p>
            </div>
          </div>
        </main>
      </div>
      <NcModal
        renderTrigger={() => null}
        isOpenProp={showDemoModal}
        renderContent={renderDemoContent}
        contentExtraClass="max-w-2xl"
        onCloseModal={() => setShowDemoModal(false)}
        modalTitle=""
      />

      <NcModal
        renderTrigger={() => null}
        isOpenProp={showBotModal}
        renderContent={renderBotContent}
        contentExtraClass="max-w-2xl"
        onCloseModal={() => setShowBotModal(false)}
        modalTitle="Say Hi to Wale"
      />
    </div>
  );
};

export default OmniMessenger;