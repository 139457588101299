// @ts-nocheck
import React, { FC, useState, useEffect } from "react";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import { Helmet } from "react-helmet";
import TimeCountDown from "./TimeCountDown";
import AccordionInfo from "./AccordionInfo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { InfluencerData } from "routers/types";
import newRequest from "utils/newRequest";
import FormItem from "components/FormItem";
import Input from "shared/Input/Input";

export interface InfluencerProfileProps {
  className?: string;
  isPreviewMode?: boolean;
  username?: string;
}

const InfluencerProfile: FC<InfluencerProfileProps> = ({
  className = "",
  isPreviewMode,
  username,
}) => {
  const [influencer, setInfluencer] = useState<InfluencerData>({});
  const [error, setError] = useState({});
  const [copy, setCopy] = useState(false);

  //
  useEffect(() => {
    newRequest
      .get(`/influencer/get/${username}`)
      .then((response) => {
        if (response.data) {
          setInfluencer(response.data);
        }
      })
      .catch((err) => setError(err));
  }, [username]);
  //

  const headerName = influencer?.username
    ? influencer.username.charAt(0).toUpperCase() +
      influencer.username.slice(1).toLowerCase()
    : "Creator";

  return (
    <div className={`nc-NftDetailPage ${className}`} data-nc-id="NftDetailPage">
      <Helmet>
        <title>{`${headerName}'s profile`}</title>
        <meta name="title" content={`${headerName}'s profile`} />
        <meta
          name="description"
          content={`Hi ${headerName}! This is your profile`}
        />
        <meta name="image" content={influencer.img} />

        {/*-- Open Graph / Facebook --*/}
        <meta property="og:title" content={`${headerName}'s profile`} />
        <meta property="og:url" content="https://getcollabo.io" />
        <meta
          property="og:description"
          content={`${influencer.username}'s profile`}
        />
        <meta property="og:image" content={influencer.img} />

        {/*-- Twitter --*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@getcollabo" />
        <meta
          name="twitter:title"
          content={`${influencer.username}'s profile`}
        />
        <meta
          name="twitter:description"
          content={`${influencer.username}'s profile`}
        />
        <meta name="twitter:image" content={influencer.img} />
        <meta name="twitter:url" content="https://getcollabo.io/teetat" />
      </Helmet>

      <div className="flex flex-col mt-8 space-y-2 xl:pr-[650px] xl:pl-4 sm:space-y-0 sm:space-x-3">
        <FormItem label={<div>rate card link</div>}>
          <div className="relative">
            <Input
              type="text"
              disabled
              className="w-full pr-10 mt-1"
              placeholder={`getcollabo.io/${
                influencer.username || "**********"
              }`}
            />
            <CopyToClipboard
              text={`https://getcollabo.io/${influencer.username}`}
              onCopy={() => setCopy(true)}
            >
              <button
                type="button"
                className="absolute inset-y-0 right-0 z-10 flex items-center px-3 text-white border-2 bg-primary-6000 dark:bg-primary-6000 border-primary-6000 rounded-2xl focus:outline-none"
              >
                {!copy ? (
                  <>
                    <svg
                      className="w-6 h-6 mr-2"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p title="Copy rate card link">Copy</p>
                  </>
                ) : (
                  <>
                    <svg
                      className="w-6 h-6 mr-2"
                      viewBox="0 0 20 21"
                      fill="currentColor"
                    >
                      <path
                        d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
                        stroke="#0483c3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
                        stroke="#0483c3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p title="Link copied">Link copied</p>
                  </>
                )}
              </button>
            </CopyToClipboard>
          </div>
        </FormItem>
      </div>

      {/* MAIn */}
      <main className="flex pl-4 pr-4 mt-10">
        <div className="grid w-full grid-cols-1 gap-10 lg:grid-cols-2 md:gap-14">
          {/* CONTENT */}
          <div className="space-y-8 lg:space-y-10">
            {/* HEADING */}
            <div className="relative">
              <NcImage
                src={influencer.img}
                containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden"
              />
            </div>

            <AccordionInfo dataProp={influencer} />
          </div>

          {/* SIDEBAR */}
          <div className="pt-10 border-t-2 lg:pt-0 xl:pl-10 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
            <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
              {/* ---------- 1 ----------  */}
              <div className="space-y-5 pb-9">
                <div className="flex items-center justify-between">
                  <Badge name="Active" color="green" />
                  <a
                    href={`https://twitter.com/intent/tweet?text=Collaborate%20with%20me%20easily%20on%20@getcollabo%F0%9F%A4%A9:%0Agetcollabo.io/${influencer?.username}%0A%0A%23getcollabo%20%23bookme%20%23collaboratewithme%20%23creator%20%23influencer%20%23contentcreator`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Share on Twitter/X"
                  >
                    <div className="inline-flex">
                      <button
                        type="button"
                        className="text-white mt-4 bg-[#1da1f2] hover:bg-[#1da1f2]/90 font-normal rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
                      >
                        <svg
                          class="w-4 h-4 mr-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 17"
                        >
                          <path
                            fillRule="evenodd"
                            d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Share on Twitter/X
                      </button>
                    </div>
                  </a>
                </div>
                <h2 className="text-2xl font-semibold sm:text-3xl lg:text-4xl">
                  {influencer.displayName}
                </h2>

                {/* ---------- 4 ----------  */}
                <div className="flex flex-col space-y-4 text-sm sm:flex-row sm:items-center sm:space-y-0 sm:space-x-8">
                  <div className="flex items-center ">
                    <img
                      src={influencer.img}
                      alt=""
                      className="rounded-full h-9 w-9"
                    />
                    <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                      <span className="flex items-center font-medium capitalize text-neutral-900 dark:text-neutral-200">
                        <span>{influencer.username}</span>
                        {/**
                         *  <VerifyIcon />
                         */}
                      </span>
                      <span className="mt-1 text-sm capitalize">
                        {influencer.industry} Creator
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              {/* ---------- 6 ----------  */}
              <div className="py-9">
                <TimeCountDown dataProp={influencer} />
              </div>

              {/* ---------- 7 ----------  */}
              {/* PRICE */}
              <div className="pt-9">
                <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
                  <div className="relative flex flex-col items-baseline flex-1 p-6 border-2 border-green-500 sm:flex-row rounded-xl">
                    <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
                      Min. Book Rate
                    </span>
                    {influencer?.deliverable &&
                      influencer?.deliverable.length > 0 && (
                        <span className="text-3xl font-semibold text-green-500 xl:text-4xl">
                          NGN{" "}
                          <span className="ml-1">
                            {influencer.deliverable
                              .reduce(
                                (min, item) =>
                                  item.rate < min ? item.rate : min,
                                Number.MAX_SAFE_INTEGER
                              )
                              .toLocaleString()}
                          </span>
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default InfluencerProfile;