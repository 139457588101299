// @ts-nocheck
import React, { FC, useState, useContext } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Tab } from "@headlessui/react";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import FormItem from "components/FormItem";
import "react-toastify/dist/ReactToastify.css";
import Login from "containers/Onboard/Login";
import { Link, useHistory } from "react-router-dom";
import { RiInstagramFill, RiTwitterFill, RiYoutubeFill } from "react-icons/ri";
import { SiTiktok } from "react-icons/si";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { InfluencerAuthContext } from "context/InfluencerAuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import newRequest from "utils/newRequest";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";

export interface ConnectedProfilesProps {
  className?: string;
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const ConnectedProfiles: FC<ConnectedProfilesProps> = ({ className = "" }) => {
  const [showModal, setShowModal] = useState(false);
  const [socialAccount, setSocialAccount] = useState("");

  const [credentials, setCredentials] = useState({
    tiktokUsername: undefined,
    instagramUsername: undefined,
    twitterUsername: undefined,
    youtubeUsername: undefined,
    facebookUsername: undefined,
    linkedinUsername: undefined,
  });
  const [error, setError] = useState(null);

  const { dispatch: authDispatch, influencer } = useContext(
    InfluencerAuthContext
  ); // rename dispatch to authDispatch
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleChange = (e: any) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleSignUp = async (e: any) => {
    e.preventDefault();
    setShowModal(false);
    setLoading(true);
    authDispatch({ type: "REGISTRATION_START" });

    // Check if all credentials are empty
    const emptyCredentialCount = Object.values(credentials).filter(
      (value) => value === ""
    ).length;

    if (emptyCredentialCount === Object.keys(credentials).length) {
      // Show an error message if all credentials are empty
      toast.error("Please fill in at least one social account", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      return; // Stop further execution
    }

    try {
      const updateInfluencer = {
        ...credentials,
      };

      const res = await newRequest.put(
        `/influencer/${influencer._id}`,
        updateInfluencer
      );
      authDispatch({ type: "UPDATE_SUCCESS", payload: res.data });
      setLoading(false);
      setCredentials(null);
      toast.success("👍 Social profiles updated successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      history.push("/socials");
    } catch (error) {
      authDispatch({
        type: "UPDATE_FAILURE",
        payload: error.response.data,
      });
      setLoading(false);
      if (error.response) {
        setError(error.response.data.error);
        toast.error(error.response.data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setError(error.message);
        toast.error(error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleConnect = (socialAccount: string) => {
    setSocialAccount(socialAccount);
    setShowModal(true);
  };

  const renderContent = () => {
    if (socialAccount.includes("facebook.com")) {
      return (
        <>
          <FormItem label="Facebook">
            <div className="flex">
              <span className="inline-flex items-center px-3 text-xs border border-r-0 rounded-l-2xl border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400">
                <FaFacebookF className="w-4 h-4" />
              </span>
              <Input
                id="facebookUsername"
                onChange={handleChange}
                className="!rounded-l-none"
                type="text"
                placeholder=""
              />
            </div>
          </FormItem>
          <ButtonPrimary
            className="flex-1 mt-4"
            sizeClass="px-5 py-1.5"
            disabled={loading}
            type="button"
            onClick={handleSignUp}
          >
            {loading ? "Saving..." : "Save"}
          </ButtonPrimary>
        </>
      );
    } else if (socialAccount.includes("instagram.com")) {
      return (
        <>
          <FormItem label="Instagram">
            <div className="flex">
              <span className="inline-flex items-center px-3 text-xs border border-r-0 rounded-l-2xl border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400">
                <RiInstagramFill className="w-4 h-4" />
              </span>
              <Input
                id="instagramUsername"
                onChange={handleChange}
                className="!rounded-l-none"
                type="text"
                placeholder=""
              />
            </div>
          </FormItem>
          <ButtonPrimary
            className="flex-1 mt-4"
            sizeClass="px-5 py-1.5"
            disabled={loading}
            type="button"
            onClick={handleSignUp}
          >
            {loading ? "Saving..." : "Save"}
          </ButtonPrimary>
        </>
      );
    } else if (socialAccount.includes("x.com")) {
      return (
        <>
          <FormItem label="Twitter/X">
            <div className="flex">
              <span className="inline-flex items-center px-3 text-xs border border-r-0 rounded-l-2xl border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400">
                <RiTwitterFill className="w-4 h-4" />
              </span>
              <Input
                id="twitterUsername"
                onChange={handleChange}
                className="!rounded-l-none"
                type="text"
                placeholder=""
              />
            </div>
          </FormItem>
          <ButtonPrimary
            className="flex-1 mt-4"
            sizeClass="px-5 py-1.5"
            disabled={loading}
            type="button"
            onClick={handleSignUp}
          >
            {loading ? "Saving..." : "Save"}
          </ButtonPrimary>
        </>
      );
    } else if (socialAccount.includes("tiktok.com")) {
      return (
        <>
          <FormItem label="TikTok">
            <div className="flex">
              <span className="inline-flex items-center px-3 text-xs border border-r-0 rounded-l-2xl border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400">
                <SiTiktok className="w-4 h-4" />
              </span>
              <Input
                id="tiktokUsername"
                onChange={handleChange}
                className="!rounded-l-none"
                type="text"
                placeholder=""
              />
            </div>
          </FormItem>
          <ButtonPrimary
            className="flex-1 mt-4"
            sizeClass="px-5 py-1.5"
            disabled={loading}
            type="button"
            onClick={handleSignUp}
          >
            {loading ? "Saving..." : "Save"}
          </ButtonPrimary>
        </>
      );
    } else if (socialAccount.includes("youtube.com")) {
      return (
        <>
          <FormItem label="YouTube">
            <div className="flex">
              <span className="inline-flex items-center px-3 text-xs border border-r-0 rounded-l-2xl border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400">
                <RiYoutubeFill className="w-4 h-4" />
              </span>
              <Input
                id="youtubeUsername"
                onChange={handleChange}
                className="!rounded-l-none"
                type="text"
                placeholder=""
              />
            </div>
          </FormItem>
          <ButtonPrimary
            className="flex-1 mt-4"
            sizeClass="px-5 py-1.5"
            disabled={loading}
            type="button"
            onClick={handleSignUp}
          >
            {loading ? "Saving..." : "Save"}
          </ButtonPrimary>
        </>
      );
    } else if (socialAccount.includes("linkedin.com/in/")) {
      return (
        <>
          <FormItem label="LinkedIn">
            <div className="flex">
              <span className="inline-flex items-center px-3 text-xs border border-r-0 rounded-l-2xl border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400">
                <FaLinkedin className="w-4 h-4" />
              </span>
              <Input
                id="linkedinUsername"
                onChange={handleChange}
                className="!rounded-l-none"
                type="text"
                placeholder=""
              />
            </div>
          </FormItem>
          <ButtonPrimary
            className="flex-1 mt-4"
            sizeClass="px-5 py-1.5"
            disabled={loading}
            type="button"
            onClick={handleSignUp}
          >
            {loading ? "Saving..." : "Save"}
          </ButtonPrimary>
        </>
      );
    }

    return null;
  };

  return (
    <div>
      {influencer ? (
        <div
          className={`nc-ConnectedProfiles pr-1 pl-1 xl:pl-4 xl:pr-4 xl:pb-[200px] lg:pb-[200px] md:pb-[200px] sm:pb-[200px] pb-[285px] ${className}`}
          data-nc-id="ConnectedProfiles"
        >
          <Helmet>
            <title>Social Accounts</title>
          </Helmet>
          <div className="py-16 mt-4 mb-24 space-y-16 lg:pb-28 lg:pt-20 lg:space-y-28">
            <main>
              <Tab.Group>
                <div className="flex flex-col justify-between lg:flex-row ">
                  <Tab.List className="flex space-x-0 overflow-x-auto sm:space-x-2">
                    <Tab>
                      {() => (
                        <div className="flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900">
                          Socials
                        </div>
                      )}
                    </Tab>
                  </Tab.List>
                </div>
              </Tab.Group>
              <span className="block mt-5 mb-3 text-sm text-neutral-500 dark:text-neutral-400">
                Connect or update your social accounts to your professional rate card
              </span>

              <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

              <div className="mt-10">
                <div className="p-6 border border-gray-200 shadow-lg bg-gray-50 rounded-xl dark:bg-gray-800 dark:border-gray-700">
                  <h5 className="mb-6 text-xl font-normal tracking-tight text-gray-900 dark:text-white">
                    Social accounts
                  </h5>

                  <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    <li className="pb-3 sm:pb-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <FaFacebookF className="w-5 h-5" />
                        </div>

                        <div className="flex-1 min-w-0">
                          <p className="text-base font-medium text-gray-900 truncate dark:text-white">
                            Facebook
                          </p>
                          {influencer.facebookUsername ? (
                            <a
                              href={`https://www.facebook.com/${influencer.facebookUsername}`}
                              target="_blank"
                              className="text-sm truncate text-primary-6000 hover:underline"
                            >
                              {`facebook.com/${influencer.facebookUsername}`}
                            </a>
                          ) : (
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              No account connected
                            </p>
                          )}
                        </div>
                        {influencer.facebookUsername ? (
                          <ButtonSecondary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                          >
                            Disconnect
                          </ButtonSecondary>
                        ) : (
                          <ButtonPrimary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                            onClick={() =>
                              handleConnect(
                                `facebook.com/${influencer.facebookUsername}`
                              )
                            }
                          >
                            Connect
                          </ButtonPrimary>
                        )}
                      </div>
                    </li>

                    <li className="py-3 sm:py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <RiInstagramFill className="w-5 h-5" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-base font-medium text-gray-900 truncate dark:text-white">
                            Instagram
                          </p>
                          {influencer.instagramUsername ? (
                            <a
                              href={`https://www.instagram.com/${influencer.instagramUsername}`}
                              target="_blank"
                              className="text-sm truncate text-primary-6000 hover:underline"
                            >
                              {`instagram.com/${influencer.instagramUsername}`}
                            </a>
                          ) : (
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              No account connected
                            </p>
                          )}
                        </div>
                        {influencer.instagramUsername ? (
                          <ButtonSecondary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                          >
                            Disconnect
                          </ButtonSecondary>
                        ) : (
                          <ButtonPrimary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                            onClick={() =>
                              handleConnect(
                                `instagram.com/${influencer.instagramUsername}`
                              )
                            }
                          >
                            Connect
                          </ButtonPrimary>
                        )}
                      </div>
                    </li>

                    <li className="py-3 sm:py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <RiTwitterFill className="w-5 h-5" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-base font-medium text-gray-900 truncate dark:text-white">
                            Twitter/X
                          </p>
                          {influencer.twitterUsername ? (
                            <a
                              href={`https://www.x.com/${influencer.twitterUsername}`}
                              target="_blank"
                              className="text-sm truncate text-primary-6000 hover:underline"
                            >
                              {`x.com/${influencer.twitterUsername}`}
                            </a>
                          ) : (
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              No account connected
                            </p>
                          )}
                        </div>
                        {influencer.twitterUsername ? (
                          <ButtonSecondary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                          >
                            Disconnect
                          </ButtonSecondary>
                        ) : (
                          <ButtonPrimary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                            onClick={() =>
                              handleConnect(
                                `x.com/${influencer.twitterUsername}`
                              )
                            }
                          >
                            Connect
                          </ButtonPrimary>
                        )}
                      </div>
                    </li>
                    <li className="py-3 sm:py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <SiTiktok className="w-5 h-5" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-base font-medium text-gray-900 truncate dark:text-white">
                            TikTok
                          </p>
                          {influencer.tiktokUsername ? (
                            <a
                              href={`https://www.tiktok.com/${influencer.tiktokUsername}`}
                              target="_blank"
                              className="text-sm truncate text-primary-6000 hover:underline"
                            >
                              {`tiktok.com/${influencer.tiktokUsername}`}
                            </a>
                          ) : (
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              No account connected
                            </p>
                          )}
                        </div>
                        {influencer.tiktokUsername ? (
                          <ButtonSecondary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                          >
                            Disconnect
                          </ButtonSecondary>
                        ) : (
                          <ButtonPrimary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                            onClick={() =>
                              handleConnect(
                                `tiktok.com/${influencer.tiktokUsername}`
                              )
                            }
                          >
                            Connect
                          </ButtonPrimary>
                        )}
                      </div>
                    </li>

                    <li className="py-3 sm:py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <RiYoutubeFill className="w-5 h-5" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-base font-medium text-gray-900 truncate dark:text-white">
                            YouTube
                          </p>
                          {influencer.youtubeUsername ? (
                            <a
                              href={`https://www.youtube.com/@/${influencer.youtubeUsername}`}
                              target="_blank"
                              className="text-sm truncate text-primary-6000 hover:underline"
                            >
                              {`youtube.com/@/${influencer.youtubeUsername}`}
                            </a>
                          ) : (
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              No account connected
                            </p>
                          )}
                        </div>
                        {influencer.youtubeUsername ? (
                          <ButtonSecondary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                          >
                            Disconnect
                          </ButtonSecondary>
                        ) : (
                          <ButtonPrimary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                            onClick={() =>
                              handleConnect(
                                `youtube.com/@/${influencer.youtubeUsername}`
                              )
                            }
                          >
                            Connect
                          </ButtonPrimary>
                        )}
                      </div>
                    </li>
                    <li className="pt-3 pb-0 sm:pt-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <FaLinkedin className="w-5 h-5" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-base font-medium text-gray-900 truncate dark:text-white">
                            LinkedIn
                          </p>
                          {influencer.linkedinUsername ? (
                            <a
                              href={`https://www.linkedin.com/in/${influencer.linkedinUsername}`}
                              target="_blank"
                              className="text-sm truncate text-primary-6000 hover:underline"
                            >
                              {`linkedin.com/in/${influencer.linkedinUsername}`}
                            </a>
                          ) : (
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              No account connected
                            </p>
                          )}
                        </div>
                        {influencer.linkedinUsername ? (
                          <ButtonSecondary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                          >
                            Disconnect
                          </ButtonSecondary>
                        ) : (
                          <ButtonPrimary
                            sizeClass="px-3.5 py-2"
                            className="font-normal"
                            type="button"
                            onClick={() =>
                              handleConnect(
                                `linkedin.com/in/${influencer.linkedinUsername}`
                              )
                            }
                          >
                            Connect
                          </ButtonPrimary>
                        )}
                      </div>
                    </li>
                  </ul>

                  <div className="flex flex-col mt-10 space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3">
                    <ButtonPrimary
                      className="flex-1"
                      disabled={loading}
                      type="button"
                      onClick={handleSignUp}
                    >
                      {loading ? "Saving..." : "Save all"}
                    </ButtonPrimary>
                    <Link
                      to={"/card"}
                      className="relative inline-flex items-center justify-center flex-1 h-auto px-4 py-3 text-sm font-medium transition-colors bg-white border rounded-full disabled:bg-opacity-70 sm:text-base sm:px-6 border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    >
                      Back
                    </Link>
                    <ToastContainer className="text-sm" />
                  </div>

                  {loading && (
                    <div className="flex items-center mt-2">
                      <div className="w-5 h-5 border-b-2 rounded-full border-primary-6000 animate-spin"></div>
                      <span className="ml-2 text-sm">
                        Updating your socials...give us a few seconds.
                      </span>
                    </div>
                  )}

                  {error && (
                    <p className="text-sm text-center text-red-500">{error}</p>
                  )}
                </div>
              </div>
            </main>
          </div>

          <NcModal
            renderTrigger={() => null}
            isOpenProp={showModal}
            renderContent={renderContent}
            contentExtraClass="max-w-sm"
            onCloseModal={() => setShowModal(false)}
            modalTitle="Type in your username"
          />
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default ConnectedProfiles;