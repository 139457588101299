// @ts-nocheck
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useLocation, useHistory } from "react-router-dom";
import { InfluencerData } from "routers/types";
import newRequest from "utils/newRequest";
import { BiShow, BiHide } from "react-icons/bi";
import Logo from "shared/Logo/Logo";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface ChangePasswordInfluencerProps {
  className?: string;
}

const ChangePasswordInfluencer: FC<ChangePasswordInfluencerProps> = ({
  className = "",
}) => {
  const location = useLocation();
  const email = location.pathname.split("/").pop();
  const [influencer, setInfluencer] = useState<InfluencerData | {}>({});
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState({
    password: undefined,
    confirmPassword: undefined,
  });
  const [error, setError] = useState({});

  //
  useEffect(() => {
    async function fetchData() {
      const response = await newRequest.get(`/influencer/getByEmail/${email}`);
      setInfluencer(response.data);
    }
    fetchData();
  }, [email]);
  //

  const history = useHistory();

  const handleChange = (e) => {
    setNewPassword((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      const sendPassword = {
        ...newPassword,
      };
      const changePassword = await newRequest.post(
        `/auth-influencer/reset-password/${influencer._id}`,
        sendPassword
      );
      console.log(changePassword.status);
      history.push("/successful");
    } catch (error) {
      console.log(error.message);
      setError({ message: "Your passwords do not match" });
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div
      className={`nc-ChangePasswordInfluencer h-screen ${className}`}
      data-nc-id="ChangePasswordInfluencer"
    >
      <Helmet>
        <title>Password Reset</title>
      </Helmet>
      <nav className="bg-white border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
          <Logo />

          <div className="hidden w-full md:block md:w-auto">
            <SwitchDarkMode />
          </div>
        </div>
      </nav>

      <section className="items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-20 sm:py-20 md:py-16">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md lg:max-w-lg md:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 sm:p-8">
              <h1 className="mb-4 text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create new password
              </h1>
              <p class="font-light mb-6 text-sm text-gray-500 dark:text-gray-400">
                Your new password must be different from previous used passwords.
              </p>

              <div className="max-w-md mx-auto">
                {/* FORM */}
                <div
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handlePasswordReset(e);
                    }
                  }}
                >
                  <form className="grid grid-cols-1 gap-4">
                    <label className="block">
                      <span className="flex items-center justify-between text-neutral-800 dark:text-neutral-200">
                        New Password
                      </span>
                      <div className="relative">
                        <Input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="mt-1"
                          placeholder="••••••••"
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? <BiShow /> : <BiHide />}
                        </button>
                      </div>
                    </label>

                    <label className="block">
                      <span className="flex items-center justify-between text-neutral-800 dark:text-neutral-200">
                        Confirm Password
                      </span>
                      <div className="relative">
                        <Input
                          type={showPassword ? "text" : "password"}
                          id="confirmPassword"
                          className="mt-1"
                          placeholder="••••••••"
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? <BiShow /> : <BiHide />}
                        </button>
                      </div>
                    </label>
                    {error && (
                      <span className="justify-center mt-2 text-sm text-center">
                        {error.message}
                      </span>
                    )}
                    <ButtonPrimary
                      className="mt-6"
                      type="button"
                      onClick={handlePasswordReset}
                    >
                      Reset Password
                    </ButtonPrimary>
                  </form>
                </div>

                {/* ==== */}
                <span className="block mt-4 text-center text-neutral-700 dark:text-neutral-300">
                  Remember your password? {` `}
                  <Link
                    className="text-primary-6000 hover:underline"
                    to="/login"
                  >
                    Login
                  </Link>
                </span>
              </div>

              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChangePasswordInfluencer;