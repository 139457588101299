// @ts-nocheck
import React, { FC, useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { InfluencerAuthContext } from "context/InfluencerAuthContext";
import newRequest from "utils/newRequest";
import Checkbox from "shared/Checkbox/Checkbox";
import { BiShow, BiHide } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "shared/Logo/Logo";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface InfluencerLoginProps {
  className?: string;
}

const InfluencerLogin: FC<InfluencerLoginProps> = ({ className = "" }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [credentials, setCredentials] = useState({
    email: undefined,
    password: undefined,
  });
  const [error, setError] = useState();

  const { loading, dispatch } = useContext(InfluencerAuthContext);

  const history = useHistory();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await newRequest.post("/auth-influencer/login", credentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });

      // Store login info in localStorage if "Remember me" is checked
      if (rememberMe) {
        localStorage.setItem("creatorEmail", credentials.email);
        localStorage.setItem("creatorPassword", credentials.password);
      }

      history.push(`/dashboard`);
    } catch (error) {
      dispatch({ type: "LOGIN_FAILURE", payload: error?.response?.data });
      if (error.response) {
        setError(error.response.data.error);
        toast.error(error.response.data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setError(error.message);
        toast.error(error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const { influencer } = useContext(InfluencerAuthContext);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("creatorEmail");
    const storedPassword = localStorage.getItem("creatorPassword");
    if (storedEmail && storedPassword) {
      setCredentials({ email: storedEmail, password: storedPassword });
      setRememberMe(true);
    }
  }, []);

  if (influencer) {
    history.push("/dashboard");
    return null;
  }

  return (
    <div className={`nc-PageLogin h-screen ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>GetCollabo</title>
      </Helmet>

      <nav className="bg-white border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
          <Logo />

          <div className="hidden w-full md:block md:w-auto">
            <SwitchDarkMode />
          </div>
        </div>
      </nav>

      <section className="items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-20 sm:py-20 md:py-16">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-lg lg:max-w-xl md:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 sm:p-8">
              
              <h1 className="mb-4 text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Log in to GetCollabo
              </h1>

              <span className="block mb-6 text-base text-left text-neutral-700 dark:text-neutral-300">
                First time? {` `}
                <Link
                  className="text-primary-6000 hover:underline "
                  to="/signup"
                >
                  Create a free account
                </Link>
              </span>

              <div className="max-w-md mx-auto">
                {/* FORM */}
                <div
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin(e);
                    }
                  }}
                >
                  <form className="grid grid-cols-1 gap-6">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Your email
                      </label>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <i className="text-2xl las la-envelope"></i>
                        </span>
                        <Input
                          type="email"
                          id="email"
                          className="!rounded-l-none"
                          placeholder="example@email.com"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <label className="block">
                      <span className="flex items-center justify-between mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Password
                        <Link
                          to="/forgot-password"
                          className="text-sm text-primary-6000"
                        >
                          Forgot password?
                        </Link>
                      </span>
                      <div className="relative">
                        <Input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="pr-10 mt-1"
                          placeholder="••••••••"
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
                          onClick={handleTogglePassword}
                        >
                          {showPassword ? <BiShow /> : <BiHide />}
                        </button>
                      </div>
                    </label>
                    <Checkbox
                      label="Remember me"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />

                    <ButtonPrimary
                      disabled={loading}
                      className="mt-8"
                      type="button"
                      onClick={handleLogin}
                    >
                      Login
                    </ButtonPrimary>
                    <ToastContainer className="text-sm" />
                    {error && (
                      <p className="justify-center text-sm text-center text-red-500">
                        {error}
                      </p>
                    )}
                  </form>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InfluencerLogin;