// @ts-nocheck
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import Label from "components/Label/Label";
import { useHistory } from "react-router-dom";
import newRequest from "utils/newRequest";
import Logo from "shared/Logo/Logo";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface ForgotPasswordInfluencerProps {
  className?: string;
}

const ForgotPasswordInfluencer: FC<ForgotPasswordInfluencerProps> = ({
  className = "",
}) => {
  const [userEmail, setUserEmail] = useState({
    email: undefined,
  });
  const [error, setError] = useState({});

  const history = useHistory();

  const handleChange = (e) => {
    setUserEmail((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      const res = await newRequest.post(
        "/auth-influencer/forgot-password",
        userEmail
      );
      console.log(res.status);
      history.push(`/verifyCreatorOTP/${userEmail.email}`);
    } catch (error) {
      console.log(error.message);
      setError({ message: "Your email is not registered" });
    }
  };

  return (
    <div
      className={`nc-ForgotPasswordInfluencer h-screen ${className}`}
      data-nc-id="ForgotPasswordInfluencer"
    >
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <nav className="bg-white border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
          <Logo />

          <div className="hidden w-full md:block md:w-auto">
            <SwitchDarkMode />
          </div>
        </div>
      </nav>

      <section className="items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-20 sm:py-20 md:py-16">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-lg lg:max-w-xl md:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 sm:p-8">
              <h1 className="mb-4 text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Forgot Password?
              </h1>
              <p class="font-light mb-6 text-sm text-gray-500 dark:text-gray-400">
                It's cool! Just type in your email and we will send you a code
                to reset your password!
              </p>

              <div className="max-w-md mx-auto">
                {/* FORM */}
                <div
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleReset(e);
                    }
                  }}
                >
                  <form className="grid grid-cols-1 gap-4">
                    <div>
                      <Label>Email Address</Label>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <i className="text-2xl las la-envelope"></i>
                        </span>
                        <Input
                          type="email"
                          id="email"
                          className="!rounded-l-none"
                          placeholder="example@email.com"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <ButtonPrimary
                      onClick={handleReset}
                      className="mt-6"
                      type="button"
                    >
                      Send code
                    </ButtonPrimary>
                    {error && (
                      <p className="justify-center text-sm text-center text-red-500">
                        {error.message}
                      </p>
                    )}
                  </form>
                </div>

                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                  Remember your password? {` `}
                  <Link className="text-primary-6000 hover:underline" to="/login">
                    Login
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPasswordInfluencer;